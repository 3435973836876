var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "mobile-container animated fadeIn",
            staticStyle: {
              "margin-left": "-16px",
              "margin-right": "-16px",
              background: "white",
            },
          },
          [
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "clearfix mb-1" }, [
                  _c(
                    "h1",
                    {
                      staticClass: "main-page-title",
                      class: { "is-pwa": _vm.$isPwa() },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(10, "Add transport")))]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content-body" },
              [
                _c("form-transport", {
                  attrs: {
                    "edit-data": _vm.transport,
                    "submit-form": _vm.submitFormTransport,
                    "is-my-transport": _vm.isMyTransport,
                    "hide-action-footer": false,
                  },
                  on: {
                    "form-transport:submit-success":
                      _vm.handleFormTransportSubmitSuccess,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }