<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div class="mobile-container animated fadeIn" style="margin-left: -16px; margin-right: -16px; background: white">
				<b-row>
					<b-col class="clearfix mb-1">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">{{ FormMSG(10, 'Add transport') }}</h1>
					</b-col>
				</b-row>

				<div class="content-body">
					<form-transport
						:edit-data="transport"
						:submit-form="submitFormTransport"
						:is-my-transport="isMyTransport"
						:hide-action-footer="false"
						@form-transport:submit-success="handleFormTransportSubmitSuccess"
					/>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import FormTransport from '@/modules/transports/components/FormTransport';
import languageMessages from '@/mixins/languageMessages';

import { getTransport } from '@/cruds/transports.crud';

import { mapGetters } from 'vuex';

export default {
	name: 'TransportMobile',

	mixins: [languageMessages],

	components: {
		FormTransport
	},

	data() {
		return {
			erreur: {},
			warning: '',

			transport: {},
			submitFormTransport: false,

			isMyTransport: true
		};
	},

	computed: {
		...mapGetters({
			currentTransportId: 'transport/currentTransportId',
			currentTransport: 'transport/currentTransport'
		})
	},

	watch: {
		currentTransport: {
			async handler(newVal) {
				if (JSON.stringify(newVal) !== '{}') {
					this.transport = newVal;
				}
			},
			immediate: true
		}
	},

	created() {
		if (this.$route.name === 'add_transport_production' || this.$route.name === 'update_transport_production') {
			this.isMyTransport = false;
		}
	},

	methods: {
		handleFormTransportSubmitSuccess() {
			this.submitFormTransport = false;
			this.$router.go(-1);
		}
	}
};
</script>

<style scoped></style>
